import React from "react";

const Travelbanner = () => {
  return (
    <div>
      <section className="page-header">
        <div className="page-header-bg-yacht"></div>
        <div className="container">
          <div className="page-header__inner">
            <div className="innerbanner">
              <h4 className="text-custom-white no-margin">Yacht Insurance</h4>
            </div>
            <h6 className="para_absdasa">Best quotes for you !!!</h6>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Travelbanner;
